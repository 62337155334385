import { estate, moneyFragment, neighborhood, owner, price } from "./partials";

import { gql } from "@apollo/client";

export const QUERY_PROJECT_DETAILS = gql`
	query projectDetails($id: ID!) {
		project(id: $id) {
			__typename
			id
			id_form
			subtitle
			slug
			active
			title
			finalization
			constructionState{
				name
			}
			isEspecial
			features {
				img
				text
			}
			backup_img
			backup_title
			description
			rooms
			bedrooms
			bathrooms
			minM2
			facilities
			images {
				id
				image
				tag
			}
			tour3d
			image_count
			architect
			percentage_sold
			percentage_finished
			ocupation
			video
			builder
			address
			commercial_units {
				__typename
				id
				code
				link
				title
				bedrooms
				bathrooms
				m2
				m2Terrace
				active
				latitude
				longitude
				pointType
				${owner}
				img
				image_count
    			youtube
				${price}
				${estate}
				${neighborhood}
				address
				price_variation {
					difference
					percentage
					date
					${moneyFragment}
				}
				commonExpenses {
					${moneyFragment}
					hidePrice
				}
				operation_type {
					id
					name
				}
				temporal_price
				temporal_currency {
					name
				}
				files {
					id
					file
					title
    			}
				neighborhood {
					name
				}
				estate {
					name
				}
				address
				showAddress
				seller {
					id
					name
					masked_phone
				}
				isExternal 
				highlight
				created_at
				updated_at
				project {
					__typename
					id
					id_form
					neighborhood { name }
					estate { name }
					address
					link
				}
				
				# bedrooms
				# property_type {
				# 	id
				# }
				# m2
				# hectares
				# bathrooms
				# property_type {
				# 	id
				# }
				# guests
				# property_type {
				# 	id
				# }
				# rooms
				# project {
				# 	id
				# 	title
				# 	description
				# 	rooms
				# 	tour3d
				# 	facilities
				# 	bedrooms
				# 	minM2
				# 	link
				# 	bedrooms_listing
				# 	bathrooms
				# 	bathrooms_listing
				# 	address
				# 	link
				# 	architect
				# 	percentage_sold
				# 	percentage_finished
				# 	ocupation
				# 	builder
				# 	minGuests
				# 	commercial_units {
				# 		id
				# 		code
				# 		link
				# 		title
				# 		bedrooms
				# 		bathrooms
				# 		m2
				# 		m2Terrace
				# 		price {
				# 			currency {
				# 				name
				# 			}
				# 			amount
				# 		}
				# 	}
				# 	images {
				# 		image
				# 		tag
				# 	}
				# 	files {
				# 		id
				# 		file
				# 		title
				# 	}
				# 	neighborhood {
				# 		name
				# 	}
				# 	estate {
				# 		name
				# 	}

				# 	typologies {
				# 		count
				# 		bathrooms
				# 		bedrooms
				# 		price {
				# 			amount
				# 			currency {
				# 				name
				# 			}
				# 		}
				# 		m2
				# 		common_expenses {
				# 			amount
				# 			currency {
				# 				name
				# 			}
				# 		}
				# 	}
				# }
				# isExternal
				# highlight
				# created_at
				# updated_at
				# image_count
				# youtube
				# files {
				# 	id
				# }
				# latitude
				# img
				# image_count
				# youtube
				# images {
				# 	image
				# }
				# title
				# description
				# facilities {
				# 	name
				# 	group
				# }
				# technicalSheet {
				# 	field
				# 	text
				# 	value
				# }
				# neighborhood {
				# 	name
				# }
				# estate {
				# 	name
				# }
				# address
				# isExternal
				# highlight
				# created_at
				# updated_at
				# img
				# youtube
				# files {
				# 	id
				# 	file
				# 	title
				# }
				# latitude
				# longitude
				# img
				# image_count
				# youtube
				# latitude
				# longitude
				# link
				# owner {
				# 	id
				# 	name
				# 	masked_phone
				# 	logo
				# 	inmoLink
				# 	inmoPropsLink
				# 	inmofull
				# 	type
				# 	has_whatsapp
				# 	... on RealEstateAgent {
				# 		subsidiaries {
				# 			id
				# 			name
				# 			masked_phone
				# 			address
				# 			office_hours
				# 		}
				# 	}
				# 	... on Developer {
				# 		subsidiaries {
				# 			id
				# 			name
				# 			masked_phone
				# 			address
				# 			office_hours
				# 		}
				# 	}
				# }
				# title
				# link
				# files {
				# 	id
				# 	file
				# 	title
				# }
			}
		}
	}
`;
